export const getInitialOwnedDigimonCardInSets = () => [
  {
    pack: "RB1",
    name: "Resurgence Booster",
    count: 37,
    cards: [],
  },
  {
    pack: "EX1",
    name: "Classic Collection",
    count: 73,
    cards: [],
  },
  {
    pack: "EX2",
    name: "Digital Hazard",
    count: 74,
    cards: [],
  },
  {
    pack: "EX3",
    name: "Dragonic Roar",
    count: 74,
    cards: [],
  },
  {
    pack: "EX4",
    name: "Alternative Being",
    count: 74,
    cards: [],
  },
  {
    pack: "EX5",
    name: "Animal Colosseum",
    count: 74,
    cards: [],
  },
  {
    pack: "EX6",
    name: "Infernal Ascension",
    count: 74,
    cards: [],
  },
  {
    pack: "EX7",
    name: "Digimon Liberator",
    count: 74,
    cards: [],
  },
  {
    pack: "BT1",
    name: "Base Set - New Evolution",
    count: 115,
    cards: [],
  },
  {
    pack: "BT2",
    name: "Base Set - Ultimate Power",
    count: 112,
    cards: [],
  },
  {
    pack: "BT3",
    name: "Base Set - Union Impact",
    count: 112,
    cards: [],
  },
  {
    pack: "BT4",
    name: "Great Legend",
    count: 115,
    cards: [],
  },
  {
    pack: "BT5",
    name: "Battle Of Omni",
    count: 112,
    cards: [],
  },
  {
    pack: "BT6",
    name: "Double Diamond",
    count: 112,
    cards: [],
  },
  {
    pack: "BT7",
    name: "Next Adventure",
    count: 112,
    cards: [],
  },
  {
    pack: "BT8",
    name: "New Awakening",
    count: 112,
    cards: [],
  },
  {
    pack: "BT9",
    name: "X-Record",
    count: 112,
    cards: [],
  },
  {
    pack: "BT10",
    name: "Xros-Encounter",
    count: 112,
    cards: [],
  },
  {
    pack: "BT11",
    name: "Dimensional Phase",
    count: 112,
    cards: [],
  },
  {
    pack: "BT12",
    name: "Across Time",
    count: 112,
    cards: [],
  },
  {
    pack: "BT13",
    name: "Versus Royal Knights",
    count: 112,
    cards: [],
  },
  {
    pack: "BT14",
    name: "Blast Ace",
    count: 102,
    cards: [],
  },
  {
    pack: "BT15",
    name: "Exceed Apocalypse",
    count: 102,
    cards: [],
  },
  {
    pack: "BT16",
    name: "Beginning Observer",
    count: 102,
    cards: [],
  },
  {
    pack: "BT17",
    name: "Xros-Encounter",
    count: 102,
    cards: [],
  },
  {
    pack: "BT18",
    name: "Special Booster V2.0",
    count: 32,
    cards: [],
  },
  {
    pack: "BT19",
    name: "Special Booster V2.0",
    count: 4,
    cards: [],
  },
  {
    pack: "ST1",
    name: "Starter Deck Gaia Red",
    count: 39,
    cards: [],
  },
  {
    pack: "ST2",
    name: "Starter Deck Cocytus Blue",
    count: 16,
    cards: [],
  },
  {
    pack: "ST3",
    name: "Starter Deck Heaven's Yellow",
    count: 16,
    cards: [],
  },
  {
    pack: "ST4",
    name: "Starter Deck Giga Green",
    count: 16,
    cards: [],
  },
  {
    pack: "ST5",
    name: "Starter Deck Machine Black",
    count: 16,
    cards: [],
  },
  {
    pack: "ST6",
    name: "Starter Deck Venomous Violet",
    count: 16,
    cards: [],
  },
  {
    pack: "ST7",
    name: "Starter Deck Gallantmon",
    count: 12,
    cards: [],
  },
  {
    pack: "ST8",
    name: "Starter Deck UlforceVeedramon",
    count: 12,
    cards: [],
  },
  {
    pack: "ST9",
    name: "Starter Deck Ultimate Ancient Dragon",
    count: 15,
    cards: [],
  },
  {
    pack: "ST10",
    name: "Starter Deck Dark Parallel World Tactician",
    count: 15,
    cards: [],
  },
  {
    pack: "ST11",
    name: "Starter Deck Special Entry Deck",
    count: 0,
    cards: [],
  },
  {
    pack: "ST12",
    name: "Starter Deck Jesmon",
    count: 16,
    cards: [],
  },
  {
    pack: "ST13",
    name: "Starter Deck RagnaLoardmon",
    count: 16,
    cards: [],
  },
  {
    pack: "ST14",
    name: "Starter Deck Set Beelzemon",
    count: 12,
    cards: [],
  },
  {
    pack: "ST15",
    name: "Starter Deck Dragon of Courage",
    count: 16,
    cards: [],
  },
  {
    pack: "ST16",
    name: "Starter Deck Wolf of Friendship",
    count: 16,
    cards: [],
  },
  {
    pack: "ST17",
    name: "Starter Deck Set Double Typhoon",
    count: 13,
    cards: [],
  },
  {
    pack: "ST18",
    name: "Starter Deck Guardian Vortex",
    count: 15,
    cards: [],
  },
  {
    pack: "ST19",
    name: "Starter Deck Fable Waltz",
    count: 15,
    cards: [],
  },
];
