<script setup lang="ts">
import PuzzleComponent from "@/components/puzzle/PuzzleComponent.vue";
import { ref } from "vue";

import GetNewCardsComponent from "@/components/cards/GetNewCardsComponent.vue";
import { useStore } from "vuex";
import { PuzzleService } from "@/services/puzzle.service";

const puzzleService = new PuzzleService();

const getNewCardsComponentRef = ref();

const store = useStore();

const puzzleSolved = async ({ difficulty, nonce }) => {
  const cards = await puzzleService.finish(
    difficulty,
    store.state.topic,
    nonce
  );
  setTimeout(async () => {
    getNewCardsComponentRef.value.showModal(cards);
  }, 1000);
};
</script>

<template>
  <PuzzleComponent @puzzle-solved="puzzleSolved"></PuzzleComponent>
  <GetNewCardsComponent ref="getNewCardsComponentRef"></GetNewCardsComponent>
</template>

<style lang="scss">
@import "@/assets/variables.scss";
.konvajs-content {
  border: 2px solid gray !important;
  border-radius: 1em;
  background-color: $background-color-header-footer;
}
</style>
