import { DigimonCard } from "@/data/CardTypes";
import digimonCardHandler from "@/db/DigimonCardHandler";
import DigimonCardHandler from "@/db/DigimonCardHandler";

class DigimonTcgLoader {
  async loadCard(cardNumber: string, name: string) {
    const baseUrl = "https://digimoncard.io/api-public/search.php";
    /*    const params = new URLSearchParams({
      n: "Agumon",
      desc: "Reveal 5 cards",
      color: "red",
      type: "digimon",
      attribute: "Vaccine",
      card: "BT1-010",
      pack: "BT01-03: Release Special Booster Ver.1.0",
      sort: "name",
      sortdirection: "desc",
      series: "Digimon Card Game",
    });*/

    let params;
    if (name === "") {
      params = new URLSearchParams({
        card: cardNumber,
        series: "Digimon Card Game",
      });
    } else {
      params = new URLSearchParams({
        n: name,
        card: cardNumber,
        series: "Digimon Card Game",
      });
    }

    const url = `${baseUrl}?${params.toString()}`;

    const card = await fetch(url).then((response: Response) => response.json());

    return card[0] as DigimonCard;
  }

  async loadCardByNumber(cardNumber: string) {
    const card = await this.loadCard(cardNumber, "");
    return card as DigimonCard;
  }

  async loadAllCards() {
    const requestOptions: RequestInit = {
      method: "GET",
      redirect: "follow",
    };

    const cards = await fetch(
      "https://digimoncard.io/api-public/getAllCards.php?sort=name&series=Digimon Card Game&sortdirection=asc",
      requestOptions
    ).then((response: Response) => response.json());

    return cards as DigimonCard[];
  }

  async loadMissingCards() {
    const allCardsFromExternal = await this.loadAllCards();

    const digimonCardHandler = new DigimonCardHandler();
    const allCardsFromDB = await digimonCardHandler.getAllCards();

    /*let counter = 0;*/
    for (const card of allCardsFromExternal) {
      if (
        !allCardsFromDB.some((dbCard) => dbCard.cardnumber === card.cardnumber)
      ) {
        const loadedCard = await this.loadCardByNumber(card.cardnumber);
        if (!loadedCard) {
          console.log("Could not load card: ", card.cardnumber);
          continue;
        }
        const result = await digimonCardHandler.insertCard(loadedCard);
        await this.delay(1000);
        /*        counter++;
        if (counter > 2) {
          break;
        }*/
      }
    }
  }

  private delay(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}

export default DigimonTcgLoader;
