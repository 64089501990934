<script setup lang="ts">
import QuotaService from "@/components/quota/quota.service";
import { onMounted, onUnmounted, ref } from "vue";
import { QuotaDto } from "@/components/quota/dto/quota.dto";
import NotificationService from "@/components/common/notification.service";
import { useStore } from "vuex";

const notificationService = new NotificationService();
const quotaService = new QuotaService();
const quota = ref<QuotaDto>();
const store = useStore();

const refreshQuota = async () => {
  try {
    quota.value = await quotaService.getQuota();
    store.dispatch("updateQuota", quota.value);
  } catch (error) {
    notificationService.open("Fetching quota failed");
  }
};

onMounted(() => {
  window.addEventListener("supabase-token-set", refreshQuota);
});

onUnmounted(() => {
  window.removeEventListener("supabase-token-set", refreshQuota);
});
</script>

<template>
  <div>Puzzle: {{ quota?.puzzle_quota }} Quiz: {{ quota?.question_quota }}</div>
</template>

<style lang="scss" scoped></style>
