<script setup lang="ts">
import headbreaker from "headbreaker";
import { computed, defineEmits, onMounted, ref } from "vue";
import NotificationService from "@/components/common/notification.service";
import InfoCircleOutlined from "@ant-design/icons-vue/InfoCircleOutlined";
import { PuzzleDifficulty } from "@/components/puzzle/puzzle-difficulty";
import { useStore } from "vuex";
import { PuzzleService } from "@/services/puzzle.service";

const store = useStore();

const emit = defineEmits(["puzzle-solved"]);

const imageToPuzzle = ref();

const notificationService = new NotificationService();
const selectedDifficulty = ref(PuzzleDifficulty.MEDIUM);
const puzzleService = new PuzzleService();
let puzzleNonce = "";

const hasQuota = computed(() => {
  return store.state.quota.puzzle_quota > 0;
});

const getNumberOfPuzzlePieces = (difficulty: PuzzleDifficulty) => {
  switch (difficulty) {
    case PuzzleDifficulty.EASY:
      return {
        horizontal: 4,
        vertical: 5,
      };
    case PuzzleDifficulty.MEDIUM:
      return {
        horizontal: 6,
        vertical: 7,
      };
    case PuzzleDifficulty.HARD:
      return {
        horizontal: 8,
        vertical: 9,
      };
  }
};

const resizeImage = (img: HTMLImageElement, maxWidth: number) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const ratio = maxWidth / img.width;
  canvas.width = maxWidth;
  canvas.height = img.height * ratio;

  ctx!.drawImage(img, 0, 0, canvas.width, canvas.height);
  return new Promise<HTMLImageElement>((resolve) => {
    const resizedImage = new Image();
    resizedImage.src = canvas.toDataURL();
    resizedImage.onload = () => resolve(resizedImage);
  });
};

const drawPuzzle = async (difficulty: PuzzleDifficulty) => {
  selectedDifficulty.value = difficulty;
  let image = new Image();
  image.crossOrigin = "anonymous";
  image.src = imageToPuzzle.value;

  image.onload = async () => {
    const resizedImage = await resizeImage(image, 400);
    const puzzlePieces = getNumberOfPuzzlePieces(difficulty);

    // Ensure canvas element is present
    const canvasElement = document.getElementById("canvas");
    if (!canvasElement) {
      console.error("Canvas element not found, drawing aborted.");
      return;
    }

    const canvas = new headbreaker.Canvas("canvas", {
      width: 1500,
      height: 1000,
      pieceSize: {
        x: resizedImage.width / puzzlePieces.horizontal,
        y: resizedImage.height / puzzlePieces.vertical,
      },
      proximity: 15,
      image: resizedImage,
      strokeWidth: 0.3,
      preventOffstageDrag: true,
      fixed: true,
      outline: new headbreaker.outline.Rounded(),
    });

    canvas.adjustImagesToPuzzleHeight();
    canvas.autogenerate({
      horizontalPiecesCount: puzzlePieces.horizontal,
      verticalPiecesCount: puzzlePieces.vertical,
    });
    canvas.shuffleGrid();
    canvas.draw();
    canvas.attachSolvedValidator();
    canvas.registerKeyboardGestures();
    canvas.onValid(() => {
      setTimeout(() => {
        emit("puzzle-solved", {
          difficulty: selectedDifficulty.value,
          nonce: puzzleNonce,
        });
        loadRandomCardAndGeneratePuzzle();
      }, 1000);
    });
  };
};

async function loadRandomCardAndGeneratePuzzle() {
  if (!hasQuota.value) {
    return;
  }
  try {
    const puzzleDto = await puzzleService.getRandomOwnedCard(store.state.topic);
    if (!puzzleDto.card) {
      return;
    }

    puzzleNonce = puzzleDto.nonce;
    imageToPuzzle.value = puzzleDto.card!.image_url_medium;
    drawPuzzle(selectedDifficulty.value);
  } catch (error) {
    notificationService.open("Fetching card for puzzle failed");
  }
}

onMounted(async () => {
  await loadRandomCardAndGeneratePuzzle();
});
</script>

<template>
  <div v-if="!imageToPuzzle">
    <h1>
      First you need a card to be able to puzzle it, go to quiz and get one.
    </h1>
  </div>
  <div class="container" v-else-if="hasQuota">
    <div class="difficulty">
      <a-tooltip>
        <template #title>This will restart the puzzle</template>
        <a-badge>
          <template #count> <info-circle-outlined /> </template>
          <label>Difficulty &nbsp;</label>
        </a-badge>
      </a-tooltip>
      <div class="difficulty-buttons">
        <a-button @click="drawPuzzle(PuzzleDifficulty.EASY)">Easy</a-button>
        <a-button @click="drawPuzzle(PuzzleDifficulty.MEDIUM)">Middle</a-button>
        <a-button @click="drawPuzzle(PuzzleDifficulty.HARD)">Hard</a-button>
        <div class="hint">
          Hint: By holding CTRL + SHIFT you can drag combined pieces
        </div>
      </div>
    </div>
    <div id="app">
      <div id="canvas"></div>
    </div>
  </div>
  <div v-else>
    <h1>ou have no puzzle quota left, come back tomorrow!</h1>
  </div>
</template>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;

  .difficulty {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .difficulty-buttons {
      margin-left: 20px;
      display: flex;
      gap: 10px;
      align-items: center;

      .hint {
        margin-left: 20px;
        font-style: italic;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .buttons {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    gap: 10px;
  }
}
</style>
