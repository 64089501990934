<script setup lang="ts">
import { inject, onMounted, ref } from "vue";
import { OwnedDigimonCardHandlerKey } from "@/injectionKeys";
import { Card, CardEntry, OwnedCardInSet } from "@/data/CardTypes";
import { getInitialOwnedDigimonCardInSets } from "@/data/OwnedDigimonCardInSets";
import { Topic } from "@/components/topic/topic";
import { getInitialOwnedOnepieceCardInSets } from "@/data/OwnedOnepieceCardInSets";
import CardListComponent from "@/components/cards/CardListComponent.vue";
import { useStore } from "vuex";

const ownedDigimonCardHandler = inject(OwnedDigimonCardHandlerKey);

const ownedCards = ref<Card[]>([]);
const activeKey = ref([]);
const sets = ref<OwnedCardInSet[]>([]);

const store = useStore();

const resetDigimonSets = () => {
  sets.value = getInitialOwnedDigimonCardInSets();
};
const resetOnepieceSets = () => {
  sets.value = getInitialOwnedOnepieceCardInSets();
};

/*
Input example:
const ownedCards: DigimonCard[] = [
  { id: 1, cardnumber: "BT1-001", name: "Agumon" },
  { id: 2, cardnumber: "BT1-002", name: "Gabumon" },
  { id: 3, cardnumber: "BT1-001", name: "Agumon" },
];

Output example:
1: {
  card: { id: 1, cardnumber: "BT1-001", name: "Agumon" },
  count: 2,
},
2: {
  card: { id: 2, cardnumber: "BT1-002", name: "Gabumon" },
  count: 1,
}
 */
const countCards = (cardCountMap: { [p: number]: CardEntry }) => {
  for (const card of ownedCards.value) {
    const cardKey = card.id;
    if (cardCountMap[cardKey]) {
      cardCountMap[cardKey].count++;
    } else {
      cardCountMap[cardKey] = { card, count: 1 };
    }
  }
};

interface CardCountMap {
  [key: number]: CardEntry;
}

const addCardsToSets = (cardCountMap: CardCountMap) => {
  for (const key in cardCountMap) {
    const cardEntry = cardCountMap[key];
    const set = sets.value.find(
      (set) =>
        set.pack ===
        cardEntry.card.cardnumber.slice(
          0,
          cardEntry.card.cardnumber.indexOf("-")
        )
    );
    if (set) {
      set.cards.push(cardEntry);
    }
  }
};

const addOwnedCardsToSets = () => {
  // Map to track card counts
  const cardCountMap: CardCountMap = {};
  countCards(cardCountMap);
  addCardsToSets(cardCountMap);
};

const removeEmptySets = () => {
  sets.value = sets.value.filter((set) => set.cards.length > 0);
};

const loadCards = async () => {
  const topic = store.state.topic;
  // TODO: REFACTOR THIS TO SERVICE
  const loadedCards = await ownedDigimonCardHandler!.getOwnedCards(topic);
  if (!loadedCards) return;

  if (topic === Topic.Digimon) {
    ownedCards.value = loadedCards.flatMap(
      (card: { [x: string]: any }) => card["digimon_cards"]
    );

    resetDigimonSets();
    addOwnedCardsToSets();
    removeEmptySets();
  } else if (topic === Topic.OnePiece) {
    ownedCards.value = loadedCards.flatMap(
      (card: { [x: string]: any }) => card["onepiece_cards"]
    );

    resetOnepieceSets();
    addOwnedCardsToSets();
    removeEmptySets();
  }
};

onMounted(async () => {
  await loadCards();
});
</script>

<template>
  <a-collapse
    v-model:activeKey="activeKey"
    :style="{
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      border: '2px solid gray',
    }"
    :bordered="false"
  >
    <a-collapse-panel v-for="set in sets" :key="set.pack" :header="set.name">
      <card-list-component :cards="set.cards"></card-list-component>
      <template #extra>{{ set.cards.length }} / {{ set.count }}</template>
    </a-collapse-panel>
  </a-collapse>
</template>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

:deep(.ant-collapse-item) {
  background-color: $background-color-header-footer;

  div.ant-collapse-header {
    color: white;
  }
}

:deep(.ant-collapse-content) {
  background-color: $background-color-header-footer;
}
</style>
