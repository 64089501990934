<template>
  <div v-if="user">
    <NavigationMenu></NavigationMenu>
  </div>
  <div v-else>
    <LoginView></LoginView>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, onUnmounted } from "vue";
import NavigationMenu from "@/views/NavigationMenu.vue";
import LoginView from "@/views/LoginView.vue";
import { supabase } from "@/lib/supabaseClient";

const user = ref();

const REFRESH_INTERVAL = 24 * 60 * 60 * 1000; // 1 mal pro tag
// I give it the type "any" because the IDEA somehow thinks it's not a number
let refreshInterval: number | any;
// const version = "0.1.0";

const checkUser = async () => {
  const {
    data: { user },
  } = await supabase.auth.getUser();
  return user;
};

// Refresh session function
const refreshSession = async () => {
  const { data, error } = await supabase.auth.refreshSession();
  if (error) {
    console.error("Error refreshing session:", error.message);
  } else {
    localStorage.setItem("supabase-token", data.session!.access_token);
    // Dispatch custom event after setting the item in localStorage
    window.dispatchEvent(new Event("supabase-token-set"));
  }
};

onUnmounted(() => {
  if (refreshInterval) clearInterval(refreshInterval);
});

onMounted(async () => {
  user.value = await checkUser();
  // add the check for version and if the version is not correct force a refresh
  if (user.value) {
    refreshInterval = setInterval(refreshSession, REFRESH_INTERVAL);
    refreshSession();
  }
});
</script>

<style lang="scss">
@import "./assets/variables.scss";

* {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: white !important;
}

.ant-btn {
  border: gray 2px solid !important;
  border-radius: 0.5em !important;
  background-color: $background-color-header-footer !important;

  &:hover {
    background-color: $background-color-header-footer-hover !important;
  }
}
</style>
