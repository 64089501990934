import axios from "axios";

export const apiClientBaseUrl =
  process.env.VUE_APP_ENV === "development"
    ? "http://localhost:3000/"
    : "https://collectstar-backend.vercel.app/";

const apiClient = axios.create({
  baseURL: apiClientBaseUrl, // Your Nest.js backend URL
  headers: {
    "Content-Type": "application/json",
  },
});

apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("supabase-token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default apiClient;
