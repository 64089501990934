import apiClient from "@/services/api";
import { Topic } from "@/components/topic/topic";
import { Card } from "@/data/CardTypes";
import { PuzzleFinishedDto } from "@/components/puzzle/dto/puzzle-finished.dto";
import { PuzzleDto } from "@/components/puzzle/dto/puzzle.dto";

export class PuzzleService {
  async getRandomOwnedCard(topic: Topic) {
    const { data } = await apiClient.get("/puzzle/card", {
      params: {
        topic: topic,
      },
    });
    return data as PuzzleDto;
  }
  async finish(difficulty: number, topic: Topic, nonce: string) {
    try {
      const { data, status } = await apiClient.post(
        "/puzzle/finish",
        new PuzzleFinishedDto(difficulty, topic, nonce)
      );
      return data as Card[];
    } catch (e) {
      throw new Error("Something went wrong please reload the page");
    }
  }
}
