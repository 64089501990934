import { createApp } from "vue";
import Antd from "ant-design-vue";
import App from "./App.vue";
import TaskHandler from "./db/TaskHandler";
import {
  AnsweredQuestionHandlerKey,
  DigimonCardHandlerKey,
  DigimonTcgLoaderKey,
  FinishedTaskHandlerKey,
  OwnedDigimonCardHandlerKey,
  TaskHandlerKey,
} from "./injectionKeys";
import FinishedTaskHandler from "@/db/FinishedTaskHandler";
import DigimonTcgLoader from "@/ext/DigimonTcgLoader";
import router from "@/router";
import "ant-design-vue/dist/antd.css";
import store from "@/store";
import "./assets/global.scss";
import AnsweredQuestionHandler from "@/db/AnsweredQuestionHandler";
import DigimonCardHandler from "@/db/DigimonCardHandler";
import OwnedDigimonCardHandler from "@/db/OwnedDigimonCardHandler";

const app = createApp(App);

// Provide the TaskHandler instance globally
app.provide(TaskHandlerKey, new TaskHandler());
app.provide(FinishedTaskHandlerKey, new FinishedTaskHandler());
app.provide(DigimonTcgLoaderKey, new DigimonTcgLoader());
app.provide(AnsweredQuestionHandlerKey, new AnsweredQuestionHandler());
app.provide(DigimonCardHandlerKey, new DigimonCardHandler());
app.provide(OwnedDigimonCardHandlerKey, new OwnedDigimonCardHandler());

app.use(router);
app.use(Antd);
app.use(store);
app.mount("#app");
