<script setup lang="ts">
import { ref } from "vue";
import { MessageOutlined } from "@ant-design/icons-vue";
import { FeedbackTopic } from "@/components/feedback/feedback.dto";
import { FeedbackService } from "@/components/feedback/feedback.service";

const feedbackService = new FeedbackService();

const visible = ref<boolean>(false);
const text = ref("");
const options = ref(
  Object.values(FeedbackTopic).filter((value) => typeof value === "string")
);
const selectedTopic = ref();
const placeholderText = ref("Additional Info");
/*const selected = ref();*/
const showModal = () => {
  visible.value = true;
};

const handleOk = async () => {
  visible.value = false;
  await feedbackService.sendFeedback(selectedTopic.value, text.value);
  text.value = "";
};

const handleChange = () => {
  if (selectedTopic.value === FeedbackTopic.QUESTION) {
    placeholderText.value =
      "Paste the full question text here and provide some info about whats wrong";
  } else {
    placeholderText.value = "Additional Info";
  }
};
</script>

<template>
  <a-button type="primary" @click="showModal" :style="{ alignSelf: 'center' }"
    ><message-outlined
  /></a-button>
  <a-modal v-model:visible="visible" title="Basic Modal" @ok="handleOk">
    <div class="feedback-component-container">
      <h1>Feedback</h1>

      <div>
        I am very happy that you help me improve this site, lets build an
        awesome game together!
      </div>

      <div class="feedback-component-container_issue-select">
        <label>Issue regarding: </label>
        <select @change="handleChange" v-model="selectedTopic">
          <option v-for="option in options" :key="option" :value="option">
            {{ option }}
          </option>
        </select>
      </div>

      <a-textarea
        v-model:value="text"
        :placeholder="placeholderText"
        :rows="4"
      />
    </div>
    <template #footer>
      <a-button key="submit" type="primary" @click="handleOk">Send</a-button>
    </template>
  </a-modal>
</template>

<style scoped lang="scss">
@import "@/assets/variables.scss";

.feedback-component-container {
  display: flex;
  flex-direction: column;
  gap: 1em;

  select,
  textarea,
  input {
    background-color: $background-color-header-footer;
    border: $button-border;
    border-radius: 0.5em;
  }

  select {
    border-radius: 0.5em;
    height: 30px;
    padding: 4px 15px 4px 15px;
    border: $button-border;
    align-items: center;

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }
  }

  &_issue-select {
    display: flex;
    align-items: center;
    gap: 1em;
  }

  &_card-input {
    display: flex;
    align-items: center;
    gap: 1em;

    input {
      width: 300px;
    }
  }
}
</style>
