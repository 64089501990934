import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
  state: {
    user: [],
    tasks: [],
    finishedTasks: [],
    quota: {
      puzzle_quota: 0,
      question_quota: 0,
    },
    topic: {},
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setTasks(state, payload) {
      state.tasks = payload;
    },
    setFinishedTasks(state, payload) {
      state.finishedTasks = payload;
    },
    setQuota(state, payload) {
      state.quota = payload;
    },
    setTopic(state, payload) {
      state.topic = payload;
    },
    decrementQuestionQuota(state) {
      state.quota.question_quota -= 1;
    },
  },
  actions: {
    updateUser({ commit }, payload) {
      commit("setUser", payload);
    },
    updateTasks({ commit }, payload) {
      commit("setTasks", payload);
    },
    updateFinishedTasks({ commit }, payload) {
      commit("setFinishedTasks", payload);
    },
    updateQuota({ commit }, payload) {
      commit("setQuota", payload);
    },
    updateTopic({ commit }, payload) {
      commit("setTopic", payload);
    },
  },
});
