import { supabase } from "@/lib/supabaseClient";
import { ResultDto } from "@/db/dto/result.dto";
import apiClient from "@/services/api";

class AnsweredQuestionHandler {
  async answerQuestion(questionId: number, answer: string): Promise<ResultDto> {
    const { data } = await apiClient.post("/questions/answer", {
      questionId: questionId,
      answer: answer,
    });
    return data as ResultDto;
  }
}

export default AnsweredQuestionHandler;
