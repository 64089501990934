import { supabase } from "@/lib/supabaseClient";
import { Task } from "@/data/types";

class TaskHandler {
  async getTasks(): Promise<Task[]> {
    const { data: tasks, error } = await supabase.from("Task").select("*");

    if (error) {
      console.error("Error fetching tasks:", error);
      throw new Error(error.message);
    }

    return tasks as Task[];
  }
}

export default TaskHandler;
