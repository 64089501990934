<script setup lang="ts">
import { supabase } from "@/lib/supabaseClient";
import TopicSelector from "@/components/topic/TopicSelector.vue";
import IntroductionComponent from "@/components/common/IntroductionComponent.vue";
import InfoPopUpComponent from "@/components/info/InfoPopUpComponent.vue";

const logout = async () => {
  const { error } = await supabase.auth.signOut();
  if (error) throw error;
  localStorage.removeItem("supabase-token");
  location.reload();
};
</script>

<template>
  <div class="Home-view-container">
    <a-button @click="logout" class="Home-view-container_logout"
      >Logout</a-button
    >
    <InfoPopUpComponent @notAcknowledgePrivacy="logout"></InfoPopUpComponent>
    <TopicSelector></TopicSelector>
    <IntroductionComponent></IntroductionComponent>
  </div>
</template>

<style lang="scss">
@import "../assets/variables.scss";

.Home-view-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  &_logout {
    align-self: flex-end;
  }
}

button {
  background-color: rgb(56, 56, 56);
}
</style>
