<script setup lang="ts">
import CardComponent from "@/components/cards/CardComponent.vue";
import { CardEntry } from "@/data/CardTypes";
import { defineProps, computed } from "vue";
import { CardsSorter } from "@/components/cards/cards-sorter.service";

const props = defineProps(["cards"]);

const cardCount = (cardEntry: CardEntry) => {
  return cardEntry.count === 1 ? 0 : cardEntry.count;
};

const sortedCards = computed(() => {
  const cardsSorter = new CardsSorter();
  return cardsSorter.sortByCardNumber([...props.cards]);
});
</script>

<template>
  <div class="card-list">
    <div class="card" v-for="cardEntry in sortedCards" :key="cardEntry.card.id">
      <a-badge :count="cardCount(cardEntry)">
        <card-component :card="cardEntry.card"></card-component>
      </a-badge>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card-list {
  display: flex;
  flex-direction: row;
  gap: 2em;
  flex-wrap: wrap;
}
</style>
