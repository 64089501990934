<script setup lang="ts">
import { useStore } from "vuex";
import { computed, onMounted, onUnmounted } from "vue";
import { SettingsService } from "@/components/topic/settings.service";

const store = useStore();
const settingsService = new SettingsService();
const topic = computed(() => store.state.topic);

const onSupabaseTokenSet = async () => {
  const topic = await settingsService.getTopic();
  store.dispatch("updateTopic", topic);
};

onMounted(() => {
  window.addEventListener("supabase-token-set", onSupabaseTokenSet);
});

onUnmounted(() => {
  window.removeEventListener("supabase-token-set", onSupabaseTokenSet);
});
</script>

<template>
  <div v-if="topic">Topic: {{ topic }}</div>
</template>

<style scoped lang="scss"></style>
