import { supabase } from "@/lib/supabaseClient";
import DigimonCardHandler from "@/db/DigimonCardHandler";
import { Card, DigimonCard, OnepieceCard } from "@/data/CardTypes";
import apiClient from "@/services/api";
import { Topic } from "@/components/topic/topic";

class OwnedDigimonCardHandler {
  async getRandomCard(topic: Topic) {
    // TODO: Put this in a service
    return await apiClient
      .get("/cards/random-owned/", {
        params: { topic: topic },
      })
      .then((response) => {
        // Do information get lost when I map it as a Card instead of a DigimonCard?
        return response.data as Card;
      });
  }

  async getOwnedCards(topic: Topic) {
    const { data: cards } = await apiClient.get("/cards/owned/", {
      params: { topic: topic },
    });
    return cards;
  }
}

export default OwnedDigimonCardHandler;
