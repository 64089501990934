import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import QuestionView from "@/views/QuestionView.vue";
import PuzzleView from "@/views/PuzzleView.vue";
import CollectionView from "@/views/CollectionView.vue";

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: HomeView,
  },
  {
    path: "/collection",
    name: "CollectionView",
    component: CollectionView,
  },
  {
    path: "/puzzle",
    name: "puzzle",
    component: PuzzleView,
  },
  {
    path: "/questions",
    name: "questions",
    component: QuestionView,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
