<script setup lang="ts">
import { defineEmits, defineProps, ref } from "vue";
import Question from "@/data/GeneratedQuestion";

const selectedAnswer = ref();

const props = defineProps({
  question: {
    type: Question,
    required: true,
  },
  disableAnswerButton: {
    type: Boolean,
  },
});
const emit = defineEmits(["question-answered"]);

const answerQuestion = () => {
  emit("question-answered", selectedAnswer.value);
};
</script>

<template>
  <div v-if="question">
    <div class="question">{{ props.question.text }}</div>
    <div class="answers">
      <a-radio-group v-model:value="selectedAnswer">
        <div v-for="answer in props.question.answers" v-bind:key="answer">
          <a-radio :value="answer" :style="{ fontSize: '20px' }">
            {{ answer }}
          </a-radio>
        </div>
      </a-radio-group>
    </div>

    <a-button @click="answerQuestion" :disabled="disableAnswerButton"
      >Submit</a-button
    >
  </div>
</template>

<style scoped lang="scss"></style>
