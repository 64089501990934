export class CardsSorter {
  sortByCardNumber(cards: any) {
    return cards.sort(
      (
        a: { card: { cardnumber: string } },
        b: { card: { cardnumber: string } }
      ) => {
        const aNr = parseInt(
          a.card.cardnumber.substring(a.card.cardnumber.indexOf("-") + 1)
        );
        const bNr = parseInt(
          b.card.cardnumber.substring(b.card.cardnumber.indexOf("-") + 1)
        );
        return (aNr || 0) - (bNr || 0);
      }
    );
  }
}
