import Question from "@/data/GeneratedQuestion";

export enum FeedbackTopic {
  CARD = "Card",
  QUESTION = "Question",
  PUZZLE = "Puzzle",
  IMPROVEMENT = "Improvement",
  OTHER = "Other",
}

export class FeedbackDto {
  public topic: FeedbackTopic;
  public text: string;

  constructor(topic: FeedbackTopic, text: string) {
    this.topic = topic;
    this.text = text;
  }
}
