import apiClient from "./api";
import { Card, DigimonCard } from "@/data/CardTypes";
import { Topic } from "@/components/topic/topic";

export class CardService {
  async getRandomCards(
    numberOfCards: number = 1,
    topic: Topic
  ): Promise<Card[]> {
    const { data } = await apiClient.get("/cards/random", {
      params: {
        count: numberOfCards,
        topic: topic,
      },
    });
    return data as Card[];
  }
}

export default CardService;
