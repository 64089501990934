<script setup lang="ts">
import { ref, watch } from "vue";
import type { SelectProps } from "ant-design-vue";
import { Topic } from "@/components/topic/topic";
import { useStore } from "vuex";
import { SettingsService } from "@/components/topic/settings.service";

const store = useStore();
const settingsService = new SettingsService();

const selectedTopic = ref(store.state.topic);
const options = ref<SelectProps["options"]>([
  {
    value: Topic.OnePiece,
    label: "One Piece",
  },
  {
    value: Topic.Digimon,
    label: "Digimon",
  },
]);

const handleChange = async () => {
  store.dispatch("updateTopic", selectedTopic.value);
  await settingsService.setTopic(selectedTopic.value);
};

watch(
  () => store.state.topic,
  (newTopic) => {
    selectedTopic.value = newTopic;
  },
  { immediate: true }
);
</script>

<template>
  <div class="topic-selector-container">
    <h1>It is time to choose:</h1>
    <select v-model="selectedTopic" @change="handleChange">
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
      >
        {{ option.label }}
      </option>
    </select>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/variables.scss";
.topic-selector-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;

  h1 {
    margin: 0;
  }

  select {
    background-color: $background-color-header-footer;
    border-radius: 0.5em;
    border: 2px solid gray;
    height: 30px;
  }
}
</style>
