export enum ResultStatus {
  CORRECT = "CORRECT",
  WRONG = "WRONG",
}

export class ResultDto {
  status: ResultStatus;
  correctAnswer: string;
  constructor(status: ResultStatus, correctAnswer: string) {
    this.status = status;
    this.correctAnswer = correctAnswer;
  }
}
