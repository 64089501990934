export const getInitialOwnedOnepieceCardInSets = () => [
  {
    pack: "ST01",
    name: "Straw Hat Crew",
    count: 46,
    cards: [],
  },
  {
    pack: "OP01",
    name: "Romance Dawn",
    count: 194,
    cards: [],
  },
];
