<script setup lang="ts">
import { ref, defineExpose } from "vue";
import { Card } from "@/data/CardTypes";
import CardComponent from "@/components/cards/CardComponent.vue";

const visible = ref<boolean>(false);
const cardsToShow = ref([] as Card[]);

const showModal = (cards: Card[]) => {
  visible.value = true;
  cardsToShow.value = cards;
};

const handleCancel = () => {
  visible.value = false;
};

const reloadImages = () => {
  const currentCards = [...cardsToShow.value];
  cardsToShow.value = [];
  setTimeout(() => {
    cardsToShow.value = currentCards;
  }, 0);
};

defineExpose({
  showModal,
});
</script>

<template>
  <div>
    <a-modal
      v-model:visible="visible"
      title="Title"
      width="70%"
      :closable="false"
    >
      <div class="images-container">
        <CardComponent
          v-for="card in cardsToShow"
          :key="card.id"
          :card="card"
        ></CardComponent>
      </div>
      <template #footer>
        <a-button
          @click="reloadImages"
          title="Sometimes the images don't load, click here to try again"
          >Reload</a-button
        >
        <a-button key="back" @click="handleCancel">Ok</a-button>
      </template>
    </a-modal>
  </div>
</template>

<style lang="scss">
@import "@/assets/variables.scss";

.ant-modal-header {
  display: none;
}

.ant-modal-body {
  background-color: $background-color;
}

div.ant-modal-footer {
  background-color: $background-color;
  border-top: none;

  .ant-btn {
    background-color: $background-color-header-footer;
  }
}

.images-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 10px;
}
</style>
