<script setup lang="ts">
import { defineProps, computed } from "vue";

const props = defineProps(["card"]);

const rarityClass = computed(() => {
  switch (props.card.cardrarity) {
    case "Common":
      return "shine-common";
    case "Uncommon":
      return "shine-uncommon";
    case "Rare":
      return "shine-rare";
    case "Promo":
    case "Leader":
    case "Alternative Art":
      return "shine-promo";
    case "Super Rare":
      return "shine-super-rare";
    case "Secret Rare":
      return "shine-secret-rare";
    default:
      return "";
  }
});
</script>

<template>
  <div :class="['card', rarityClass]">
    <a-image
      :preview-mask="false"
      :src="card.image_url_medium"
      :alt="card.cardnumber"
      :style="{ borderRadius: '1.2em', maxWidth: '300px' }"
    />
  </div>
</template>

<style lang="scss">
.card {
  border-radius: 1.2em !important;
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.2);
}

.ant-image-preview-body {
  background: rgba(0, 0, 0, 0.75);
}

@keyframes shine-uncommon {
  0%,
  100% {
    box-shadow: 0 0 5px rgba(40, 178, 26, 0.5);
    border-radius: 1.2em;
  }
  50% {
    box-shadow: 0 0 20px rgb(40, 178, 26, 1);
  }
}

@keyframes shine-rare {
  0%,
  100% {
    box-shadow: 0 0 5px rgba(0, 60, 255, 0.5);
    border-radius: 1.2em;
  }
  50% {
    box-shadow: 0 0 20px rgba(0, 60, 255, 1);
  }
}

@keyframes shine-promo {
  0%,
  100% {
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
    border-radius: 1.2em;
  }
  50% {
    box-shadow: 0 0 20px rgb(255, 255, 255);
  }
}

@keyframes shine-super-rare {
  0%,
  100% {
    box-shadow: 0 0 5px rgba(247, 255, 0, 0.5);
    border-radius: 1.2em;
  }
  50% {
    box-shadow: 0 0 20px rgba(247, 255, 0, 1);
  }
}

@keyframes shine-secret-rare {
  0%,
  100% {
    box-shadow: 0 0 5px rgba(255, 0, 0, 0.5);
    border-radius: 1.2em;
  }
  50% {
    box-shadow: 0 0 20px rgba(255, 0, 0, 1);
  }
}

.card {
  transition: box-shadow 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
}

.shine-uncommon {
  animation: shine-uncommon 3s infinite;
}

.shine-rare {
  animation: shine-rare 3s infinite;
}

.shine-promo {
  animation: shine-promo 3s infinite;
}

.shine-super-rare {
  animation: shine-super-rare 3s infinite;
}

.shine-secret-rare {
  animation: shine-secret-rare 3s infinite;
}
</style>
