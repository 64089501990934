import { supabase } from "@/lib/supabaseClient";
import { Task } from "@/data/types";

class FinishedTaskHandler {
  async finishTask(taskId: number, userId: string): Promise<void> {
    const { data: tasks, error } = await supabase
      .from("FinishedTask")
      .insert([{ task_id: taskId, user_id: userId }]);

    if (error) {
      console.error("Error fetching tasks:", error);
      throw new Error(error.message);
    }
  }

  async loadFinishedTasks(userId: string): Promise<Task[]> {
    try {
      const { data, error } = await supabase
        .from("FinishedTask")
        .select("Task(id, name, created_at, difficulty)")
        .eq("user_id", userId);

      if (error) {
        throw new Error(`Error fetching tasks: ${error.message}`);
      }

      if (!data) {
        return [];
      }

      return data.map((element: any) => element.Task);
    } catch (error: any) {
      console.error(error);
      throw new Error(`Failed to load finished tasks: ${error.message}`);
    }
  }
}

export default FinishedTaskHandler;
