import { Topic } from "@/components/topic/topic";
import apiClient from "@/services/api";

export class SettingsService {
  async setTopic(topic: Topic) {
    await apiClient.put("/settings/topic", {
      topic: topic,
    });
  }

  async getTopic(): Promise<Topic> {
    const { data } = await apiClient.get("/settings/topic");
    return data as Topic;
  }
}
