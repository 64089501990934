// from https://documenter.getpostman.com/view/14059948/TzecB4fH
export interface Card {
  id: number;
  name: string;
  cardrarity: string;
  cardnumber: string;
  image_url_medium: string;
}

export interface DigimonCard extends Card {
  id: number;
  artist: string;
  attribute: string;
  card_sets: string[];
  cardnumber: string;
  cardrarity: string;
  color: string;
  digi_type: string;
  dp: number;
  evolution_cost: number;
  image_url_medium: string;
  level: number;
  maineffect: string;
  name: string;
  play_cost: number;
  set_name: string;
  soureeffect: string | null;
  stage: string;
  type: string;
}

export interface OnepieceCard extends Card {
  id: number;
  name: string;
  type: string;
  color: string; // enum
  attribute: string; // enum
  power: number;
  life: number;
  cost: number;
  cardrarity: string; // enum
  cardnumber: string;
  category: string; // enum
  set_name: string;
  image_url_medium: string;
  image_url_large: string;
}

/*export enum Rarity {
  Common = "Common",
  Uncommon = "Uncommon",
  Promo = "Promo",
  Unknown = "Unknown",
  AlternativeArt = "Alternative Art",
  Rare = "Rare",
  SuperRare = "Super Rare",
  SecretRare = "Secret Rare",
}*/

// These are the names of the views in supabase which returns me a random card with the following rarity
export enum DigimonCardRarity {
  Common = "random_common_digimon",
  Uncommon = "random_uncommon_digimon",
  Promo = "random_promo_digimon",
  Unknown = "random_unknown_digimon",
  AlternativeArt = "random_alternative_art_digimon",
  Rare = "random_rare_digimon",
  SuperRare = "random_super_rare_digimon",
  SecretRare = "random_secret_rare_digimon",
}

export interface OwnedCardInSet {
  pack: string;
  name: string;
  count: number;
  cards: CardEntry[];
}

export interface CardEntry {
  card: Card;
  count: number;
}
