import apiClient from "@/services/api";
import { QuotaDto } from "@/components/quota/dto/quota.dto";

class QuotaService {
  public async getQuota() {
    const { data } = await apiClient.get("/quota");
    return data as QuotaDto;
  }
}

export default QuotaService;
