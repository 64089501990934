<script setup lang="ts">
import { computed, inject, ref } from "vue";
import Question from "@/data/GeneratedQuestion";
import { AnsweredQuestionHandlerKey } from "@/injectionKeys";
import apiClient from "@/services/api";
import GetNewCardsComponent from "@/components/cards/GetNewCardsComponent.vue";
import { ResultDto, ResultStatus } from "@/db/dto/result.dto";
import CardService from "@/services/cardService";
import { useStore } from "vuex";
import QuestionComponent from "@/components/questions/QuestionComponent.vue";
import ResultComponent from "@/components/questions/ResultComponent.vue";

const store = useStore();

const currentQuestion = ref();
const result = ref();
const getNewCardsComponentRef = ref();
const loadingQuestion = ref(false);

const answeredQuestionHandler = inject(AnsweredQuestionHandlerKey);
const digimonCardService = new CardService();

const hasQuota = computed(() => {
  return store.state.quota.question_quota > 0;
});

const loadNewQuestion = async () => {
  loadingQuestion.value = true;
  const question = await loadQuestionFromBackend();
  currentQuestion.value = question;
  result.value = undefined;
  loadingQuestion.value = false;
};

const answerQuestion = async (selectedAnswer: string) => {
  if (!currentQuestion.value || !currentQuestion.value.id) {
    console.error("No valid question ID found!");
    return;
  }

  result.value = (await answeredQuestionHandler?.answerQuestion(
    currentQuestion.value.id,
    selectedAnswer
  )) as ResultDto;
  if (result.value.status === ResultStatus.CORRECT) {
    const card = await digimonCardService.getRandomCards(1, store.state.topic);
    getNewCardsComponentRef.value.showModal(card);
  }
};

const loadQuestionFromBackend = async () => {
  store.commit("decrementQuestionQuota");
  const { data } = await apiClient.get("/questions/question", {
    params: {
      topic: store.state.topic,
    },
  });
  return data as Question;
};

const enableLoadNewQuestionButton = computed(
  () => currentQuestion.value === undefined || result.value !== undefined
);
</script>

<template>
  <div v-if="hasQuota" class="question-view-container">
    <a-button
      @click="loadNewQuestion"
      :style="{ width: '200px' }"
      :loading="loadingQuestion"
      :disabled="!enableLoadNewQuestionButton"
      >Get a new question</a-button
    >
    <QuestionComponent
      :question="currentQuestion"
      @question-answered="answerQuestion"
      :disable-answer-button="!!result"
    ></QuestionComponent>

    <ResultComponent :result="result" v-if="result"></ResultComponent>

    <GetNewCardsComponent ref="getNewCardsComponentRef"></GetNewCardsComponent>
  </div>
  <div v-else>
    <h1>You have no quiz quota left, come back tomorrow!</h1>
  </div>
</template>

<style lang="scss">
@import "@/assets/variables.scss";

.question-view-container {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .question {
    font-size: 30px;
    margin-bottom: 20px;
  }

  .answers {
    margin-bottom: 20px;
  }
}
</style>
