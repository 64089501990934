class Question {
  id: number;
  text: string;
  answers: string[];

  constructor(id: number, text: string, answers: string[]) {
    this.id = id;
    this.text = text;
    this.answers = answers;
  }
}

export default Question;
