import { InjectionKey } from "vue";
import TaskHandler from "./db/TaskHandler";
import FinishedTaskHandler from "@/db/FinishedTaskHandler";
import DigimonTcgLoader from "@/ext/DigimonTcgLoader";
import AnsweredQuestionHandler from "@/db/AnsweredQuestionHandler";
import DigimonCardHandler from "@/db/DigimonCardHandler";
import OwnedDigimonCardHandler from "@/db/OwnedDigimonCardHandler";

export const TaskHandlerKey: InjectionKey<TaskHandler> = Symbol("TaskHandler");
export const FinishedTaskHandlerKey: InjectionKey<FinishedTaskHandler> = Symbol(
  "FinishedTaskHandlerKey"
);
export const DigimonTcgLoaderKey: InjectionKey<DigimonTcgLoader> = Symbol(
  "DigimonTcgLoaderKey"
);
export const AnsweredQuestionHandlerKey: InjectionKey<AnsweredQuestionHandler> =
  Symbol("AnsweredQuestionHandlerKey");
export const DigimonCardHandlerKey: InjectionKey<DigimonCardHandler> = Symbol(
  "DigimonCardHandlerKey"
);

export const OwnedDigimonCardHandlerKey: InjectionKey<OwnedDigimonCardHandler> =
  Symbol("OwnedDigimonCardHandlerKey");
