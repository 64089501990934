import { supabase } from "@/lib/supabaseClient";
import DigimonTcgLoader from "@/ext/DigimonTcgLoader";
import { DigimonCard, DigimonCardRarity } from "@/data/CardTypes";

const tableName: string = "DigimonCards";

const digimon_rarity_views = [
  "random_common_digimon",
  "random_uncommon_digimon",
  "random_promo_digimon",
  "random_rare_digimon",
  "random_alternative_art_digimon",
  "random_super_rare_digimon",
  "random_secret_rare_digimon",
];

// TODO: Refactor this class because it does way more than just interacting with the db

class DigimonCardHandler {
  rollRarity(): string {
    const chanceUncommon = 0.3333;
    const chanceRare = 0.1667;
    const changePromoOrAlternativeArt = 0.0833;
    const chanceSuperRare = 0.0417;
    const chanceSecretRare = 0.0208;

    const roll = Math.random();

    if (roll < chanceSecretRare) {
      return DigimonCardRarity.SecretRare;
    } else if (roll < chanceSuperRare) {
      return DigimonCardRarity.SuperRare;
    } else if (roll < changePromoOrAlternativeArt) {
      const rollForPromo = Math.random();
      return rollForPromo >= 0.5
        ? DigimonCardRarity.Promo
        : DigimonCardRarity.AlternativeArt;
    } else if (roll < chanceRare) {
      return DigimonCardRarity.Rare;
    } else if (roll < chanceUncommon) {
      return DigimonCardRarity.Uncommon;
    }

    return DigimonCardRarity.Common;
  }

  async getRandomCard(): Promise<DigimonCard> {
    const rarity = this.rollRarity();

    const { data: card, error } = await supabase
      .from(rarity)
      .select("*")
      .single();

    return card as DigimonCard;
  }

  async getCard(id: number): Promise<DigimonCard> {
    const { data: card, error } = await supabase
      .from(tableName)
      .select("*")
      .eq("id", id)
      .single();
    return card as DigimonCard;
  }

  async getAllCards(): Promise<DigimonCard[]> {
    const pageSize = 1000; // Number of rows to fetch per request
    let allCards: DigimonCard[] = [];
    let from = 0;

    // eslint-disable-next-line no-constant-condition
    while (true) {
      const { data: cards, error } = await supabase
        .from(tableName)
        .select("*")
        .range(from, from + pageSize - 1);

      if (error) {
        console.error("Error fetching data:", error);
        break;
      }

      if (cards && cards.length > 0) {
        allCards = allCards.concat(cards);
        from += pageSize;
      } else {
        break; // Exit the loop when no more rows are returned
      }
    }

    return allCards;
  }

  async insertCard(digimonCard: DigimonCard) {
    const { data, error } = await supabase.from(tableName).insert([
      {
        name: digimonCard.name,
        type: digimonCard.type,
        color: digimonCard.color,
        stage: digimonCard.stage,
        digi_type: digimonCard.digi_type,
        attribute: digimonCard.attribute,
        level: digimonCard.level,
        play_cost: digimonCard.play_cost,
        evolution_cost: digimonCard.evolution_cost,
        cardrarity: digimonCard.cardrarity,
        artist: digimonCard.artist,
        dp: digimonCard.dp,
        cardnumber: digimonCard.cardnumber,
        maineffect: digimonCard.maineffect,
        soureeffect: digimonCard.soureeffect,
        set_name: digimonCard.set_name,
        image_url_medium: digimonCard.image_url_medium,
      },
    ]);
  }
}

export default DigimonCardHandler;
