<script setup lang="ts">
import { defineEmits, onMounted, ref } from "vue";

const showModal = ref<boolean>(false);
const emit = defineEmits(["not-acknowledge-privacy"]);

onMounted(() => {
  const privacy = localStorage.getItem("privacy-read");
  if (!privacy) {
    showModal.value = true;
  }
});

const handleOk = () => {
  showModal.value = false;
  localStorage.setItem("privacy-read", "true");
};

const handleCancel = () => {
  emit("not-acknowledge-privacy");
};
</script>

<template>
  <div>
    <a-modal
      v-model:visible="showModal"
      @ok="handleOk"
      :okText="'Acknowledge'"
      @cancel="handleCancel"
    >
      Welcome to CollectiQuiz! <br />
      <br />Please note that this website is a non-commercial project. We do not
      hold any ownership over the resources used on this website, including but
      not limited to trademarks, logos, and images, which remain the property of
      their respective owners. <br />
      <br />
      Privacy Notice: We take your privacy seriously. Any personal data
      collected during registration or use of this website will be used solely
      for the purpose of providing and improving our services. We will not share
      your personal data with third parties without your explicit consent,
      except as required by law. <br />
      <br />
      By continuing to use this site, you acknowledge and agree to these terms.
    </a-modal>
  </div>
</template>

<style scoped lang="scss"></style>
