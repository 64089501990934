<script setup lang="ts">
import { defineEmits } from "vue";
import { supabase } from "@/lib/supabaseClient";

const emit = defineEmits(["loggedIn"]);

const getURL = () => {
  let url =
    process?.env?.VUE_APP_PUBLIC_SITE_URL ?? // Set this to your site URL in production env.
    process?.env?.VUE_APP_VERCEL_URL ?? // Automatically set by Vercel.
    "http://localhost:3000/";
  // Make sure to include `https://` when not localhost.
  url = url.startsWith("http") ? url : `https://${url}`;
  // Make sure to include a trailing `/`.
  url = url.endsWith("/") ? url : `${url}/`;
  return url;
};

const login = async (provider: string) => {
  let { error } = await supabase.auth.signInWithOAuth({
    provider: provider,
    options: {
      redirectTo: getURL(),
    },
  });

  if (error) throw error;
};

const signInThroughGithub = async () => {
  setTimeout(async () => {
    await login("github");
  }, 1000);
  await handleOAuthCallback();
};

const signInThroughGoogle = async () => {
  setTimeout(async () => {
    await login("google");
  }, 1000);
  await handleOAuthCallback();
  emit("loggedIn");
};

const handleOAuthCallback = async () => {
  const { data, error } = await supabase.auth.getSession();
  if (error) throw error;
  if (data.session) {
    localStorage.setItem("supabase-token", data.session!.access_token);
  }
};
</script>

<template>
  <div class="login-container">
    <h1>Welcome to CollectiQuiz!</h1>
    <button @click="signInThroughGithub" class="oauth-button">
      Sign-in with GitHub
    </button>
    <button @click="signInThroughGoogle" class="oauth-button">
      Sign-in with Google
    </button>
  </div>
</template>

<style scoped lang="scss">
@import "../assets/variables.scss";

.login-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: $background-color;
}
</style>
