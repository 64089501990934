<script setup lang="ts"></script>

<template>
  <div class="introduction-container">
    Welcome to CollectiQuiz!<br />
    <br />
    I've always loved collecting cards, but I often lack the resources, space,
    and time to collect them all. That's why I wanted a digital way to enjoy
    this hobby. Since there wasn't a comprehensive platform available, I decided
    to create my own project. I hope you enjoy collecting these little
    masterpieces from your favorite anime as much as I do!
    <br /><br />
    So, what's the deal? You can collect cards by playing games or quizzes.
    First, choose the anime you want to collect cards for (don't worry, you can
    always change your choice). Currently, you can earn cards by solving puzzles
    and answering quizzes, but more options will be available soon.
    <br /><br />
    Your feedback is very important to me! Please use the feedback button in the
    upper right corner of the navigation bar to report:
    <ul>
      <li>Any bugs you encounter</li>
      <li>Any questions you find incorrect or unclear</li>
      <li>Any suggestions for improving the page</li>
      <li>Any other ideas you have</li>
    </ul>
    A bit of explanation: In the upper right corner, you can see the topic you
    currently selected (which you can change in the home menu) and your game
    quota. Once you've used up your quota for quizzes and/or puzzles, you'll
    need to wait until the next morning for it to refill. <br /><br />
    These are the things I am going to work on next:
    <ul>
      <li>Trading</li>
      <li>Leaderboards</li>
      <li>More games, cards, animes</li>
      <li>Profile page</li>
      <li>...</li>
    </ul>
    Thank you very much for your support and have fun!
  </div>
</template>

<style scoped lang="scss">
.introduction-container {
  padding: 20px;
  border-top: 2px solid gray;
  //border-radius: 10px;
}
</style>
