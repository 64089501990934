<script setup lang="ts">
import QuotaComponent from "@/components/quota/QuotaComponent.vue";
import TopicComponent from "@/components/topic/TopicComponent.vue";
import FeedbackComponent from "@/components/feedback/FeedbackComponent.vue";
import { ref, watch, onMounted } from "vue";
import { useRoute } from "vue-router";

const selectedKeys = ref([]);

// Watch for route changes and update selectedKeys accordingly
const route = useRoute();
watch(
  () => route.path,
  (newPath) => {
    selectRoute(newPath);
  }
);

function selectRoute(routePath: any) {
  switch (routePath) {
    case "/":
      selectedKeys.value = ["1"];
      break;
    case "/collection":
      selectedKeys.value = ["3"];
      break;
    case "/puzzle":
      selectedKeys.value = ["4"];
      break;
    case "/questions":
      selectedKeys.value = ["5"];
      break;
    default:
      selectedKeys.value = [];
      break;
  }
}

// Initialize selectedKeys based on initial route
onMounted(() => {
  selectRoute(route.path);
});
</script>

<template>
  <a-layout
    :style="{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }"
  >
    <a-layout-header
      :style="{
        position: 'fixed',
        zIndex: 1,
        width: '100%',
        top: 0,
        backgroundColor: '#383838',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingRight: 0,
        borderBottom: '2px solid gray',
      }"
    >
      <div class="left-menu">
        <a-menu
          v-model:selectedKeys="selectedKeys"
          mode="horizontal"
          :style="{
            lineHeight: '60px',
            backgroundColor: '#383838',
            borderBottom: 0,
          }"
        >
          <a-menu-item key="1">
            <router-link to="/">Home</router-link>
          </a-menu-item>
          <a-menu-item key="3">
            <router-link to="/collection">Collection</router-link>
          </a-menu-item>
          <a-menu-item key="4">
            <router-link to="/puzzle">Puzzle</router-link>
          </a-menu-item>
          <a-menu-item key="5">
            <router-link to="/questions">Quiz</router-link>
          </a-menu-item>
        </a-menu>
      </div>
      <div
        class="right-menu"
        :style="{ color: '#FFFFFF', marginRight: '24px' }"
      >
        <div class="navigation-menu-header_right-side">
          <TopicComponent class="hide-on-small-screen"></TopicComponent>
          <QuotaComponent class="hide-on-smaller-screen"></QuotaComponent>
          <FeedbackComponent></FeedbackComponent>
        </div>
      </div>
    </a-layout-header>
    <a-layout-content :style="{ marginTop: '64px', flex: 1, overflow: 'auto' }">
      <router-view></router-view>
    </a-layout-content>
    <a-layout-footer
      :style="{
        textAlign: 'center',
        height: '70px',
        borderTop: '2px solid gray',
      }"
    >
      <div>
        All trademarks, logos, and images of cards used on this website are the
        property of Bandai Namco Entertainment Inc.
      </div>
    </a-layout-footer>
  </a-layout>
</template>

<style lang="scss">
@import "@/assets/variables.scss";

.logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 24px 16px 0;
  float: left;
}

.site-layout {
  &.site-layout-background {
    background: #fff;
  }
}

.ant-layout-content {
  background-color: $background-color;
  padding: 30px;
}

.ant-menu-horizontal:not(.ant-menu-dark) {
  > .ant-menu-item {
    a {
      color: white;

      &:hover,
      &.ant-menu-item-selected {
        color: white;
      }
    }

    &:hover,
    &.ant-menu-item-selected {
      background-color: $background-color-header-footer-hover;
    }
  }
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  color: white;
}

.ant-layout-footer:not(.ant-menu-dark) {
  background-color: rgb(56, 56, 56);
  color: white;
}

.navigation-menu-header_right-side {
  display: flex;
  gap: 2em;
}

@media (max-width: 900px) {
  .hide-on-small-screen {
    display: none;
  }
}

@media (max-width: 750px) {
  .hide-on-smaller-screen {
    display: none;
  }
}
</style>
