import { Topic } from "@/components/topic/topic";

export class PuzzleFinishedDto {
  public difficulty: number;
  public topic: Topic;
  public nonce: string;

  constructor(difficulty: number, topic: Topic, nonce: string) {
    this.difficulty = difficulty;
    this.topic = topic;
    this.nonce = nonce;
  }
}
